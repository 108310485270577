import React, {useState, useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {GoVerified} from 'react-icons/go'
import {MdNotificationsActive} from 'react-icons/md'
import {MdDangerous} from 'react-icons/md'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import {KTSVG} from '../../../../_metronic/helpers'
import {AiOutlineSelect} from 'react-icons/ai'
import {
  copyServicesByParentIdAction,
  serviceOneSelector,
  serviceTwoSelector,
  updateServiceParentAction,
  updateServicesAction,
} from '../../../reducers/servicesReducer'
import {AppDispatch} from '../../../store'
import {useDispatch, useSelector} from 'react-redux'
import {CgPathDivide} from 'react-icons/cg'
import {fetchIconAction, iconListSelector} from '../../../reducers/iconReducer'
import useSvgColor from '../../../../hooks/useSvgColor'
import InfoWrapper from '../../../modules/common/SectionInfo'
import {MuiTooltip} from '../../../common/Tooltip'
import {array_to_CSV} from '../../../../util'
import {MdDone} from 'react-icons/md'

type serviceCardProps = {
  data: any
  copy?: boolean
  handleServicesChange?: any
  cData?: any
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'white',
  boxShadow: 24,
  p: 4,
  height: 600,
  overflow: 'scroll',
}
const style2 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll',
}

const ServiceCard = ({data, copy, handleServicesChange, cData}: serviceCardProps) => {
  const channels = useSelector(serviceOneSelector)
  const Id = useParams()

  const activeSecondLvl = channels?.filter((f: any) => f?.id == Id.oneId)

  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [search, setSearch] = useState<any>('')
  const [submissionData, setSubmissionData] = useState<any>({})
  const [updateParentData, setUpdateParentData] = useState<any>({})
  const [open1, setOpen1] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [error, setError] = useState(false)
  const [isCopy, setIsCopy] = useState(false)
  const [copyData, setCopyData] = useState<any>(null)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleOpen1 = () => setOpen1(true)
  const handleClose1 = () => setOpen1(false)
  const handleOpen2 = () => setOpen2(true)
  const handleClose2 = () => setOpen2(false)

  const dispatch = useDispatch<AppDispatch>()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    if (data?.level == 1) {
      const finalData = {
        ...submissionData,
        isLeafNode: '1',
        level: '1',
      }
      dispatch(updateServicesAction({data: finalData, id: submissionData.id, level: 1}))
    } else {
      const finalData = {
        ...submissionData,
        isLeafNode: '1',
        level: '2',
        parentId: submissionData?.parentId,
      }
      dispatch(updateServicesAction({data: finalData, id: submissionData.id, level: 2}))
    }
    // const {data, success, message} = res
  }

  const handleParentUpdate = async (e: any) => {
    e.preventDefault()
    if (updateParentData.newParentId) {
      dispatch(
        updateServiceParentAction({data: updateParentData, serviceId: updateParentData.serviceId})
      )
      handleClose2()
    } else {
      setError(true)
    }
  }

  const icons = useSelector(iconListSelector)

  const handleChange = (e: any) => {
    if (e.target.value === '') {
      setSearch(null)
    } else {
      // setSearching(true);
      setSearch(e.target.value.toUpperCase())
    }
    // console.log(search);
  }

  const handleCopySubmit = () => {
    const finalData = {
      ...copyData,
      serviceIds: array_to_CSV(copyData?.serviceIds),
    }
    setIsCopy(false)
    dispatch(copyServicesByParentIdAction({data: finalData, setCopyData: setCopyData}))
  }

  return (
    <div className={`col-md-3`}>
      <div
        style={{backgroundColor: data?.isActive === 1 ? '#fff' : '#b4b4b5', position: 'relative'}}
        className={`my-2 text-center rounded border hover-light p-4 ${
          copy
            ? cData?.serviceIds?.some((id: number) => id === data?.id)
              ? 'border-primary'
              : 'border-secondary'
            : 'border-light'
        }`}
        onClick={() =>
          handleServicesChange({
            ...cData,
            serviceIds: data?.id,
          })
        }
      >
        {copy ? (
          cData?.serviceIds?.some((id: number) => id === data?.id) ? (
            <div
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
            >
              <MdDone className='text-primary' size={'1.5rem'} />
            </div>
          ) : (
            <div
              style={{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
            >
              <MdDone className='text-secondary' size={'1.5rem'} />
            </div>
          )
        ) : null}
        <div className='text-start my-auto'>
          <div className='d-flex flex-column justify-content-center align-items-center'>
            <img
              style={{filter: data?.isActive === 1 ? 'none' : 'grayscale(100%)'}}
              src={data?.icon}
              alt='icon'
              height={50}
            />
            {data.level != 3 ? (
              <h4
                className='text-primary text-center mt-3 pointer text-capitalize'
                onClick={() => {
                  setSubmissionData({
                    ...submissionData,
                    id: data?.id,
                    name: data?.name,
                    icon: data?.icon,
                    isMemberDefault: data?.isMemberDefault,
                    parentId: data?.parentId,
                    isActive: data?.isActive,
                    isAdmin: data?.isAdmin,
                  })
                  handleOpen1()
                }}
              >
                {data?.name}
                {/* {data?.level == 1 ? ( */}
                {data?.isMemberDefault === 1 ? (
                  <GoVerified
                    className='mx-1 pointer'
                    title='Default member'
                    style={{color: 'green'}}
                    size={'1rem'}
                  />
                ) : (
                  <MdDangerous
                    className='mx-1 pointer'
                    title='Not default'
                    style={{color: 'red'}}
                    size={'1rem'}
                  />
                )}
                {/* ) : null} */}
              </h4>
            ) : (
              <h4
                className='text-primary pointer text-center mt-3 text-capitalize'
                onClick={() => !copy && navigate(`/channels/update/${data?.id}`)}
              >
                {data?.name}
              </h4>
            )}
          </div>
        </div>
        <div>
          {data.level != 3 ? (
            <div
              className={`btn btn-sm py-1 px-15 ${
                data?.isActive === 1 ? 'btn-primary' : 'btn-secondary'
              }`}
              onClick={() =>
                navigate(
                  data.level == 1
                    ? `/channels/lv1/${data?.id}`
                    : `/channels/lv1/${data?.parentId}/lv2/${data?.id}`,
                  {
                    state: data?.name,
                  }
                )
              }
            >
              {data.level == 1 ? 'Sub Channels' : 'Services'}
            </div>
          ) : (
            <h3 className='text-center'>{data?.price ? <span> ${data?.price}</span> : null}</h3>
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-header mb-2'>
            <h2 className='fw-bolder'>Icons</h2>
            <div
              onClick={handleClose}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <div className='card-title text-center'>
            {/* begin::Search */}
            <div className='d-flex align-items-center my-3 m-auto'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-1 position-absolute ms-6'
              />
              <input
                onChange={handleChange}
                type='text'
                data-kt-user-table-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search icons'
              />
            </div>
            {/* end::Search */}
          </div>
          <div className='row mt-4'>
            {search
              ? icons
                  ?.filter((f) => f?.name?.toUpperCase().includes(search))
                  ?.map((icon: any, index: any) => (
                    <div className='col-3 rounded text-center my-1' key={index}>
                      <div
                        style={{width: '100px', height: '50px'}}
                        className='symbol symbol-35px text-center bg-secondary align-items-center pointer'
                        onClick={() => {
                          setSubmissionData({
                            ...submissionData,
                            icon: icon.icon,
                          })
                          handleClose()
                        }}
                        data-bs-toggle='tooltip'
                        title='Ana Stone'
                      >
                        <p className='fw-bold fs-4 text-primary mb-0 mt-4'>
                          <img src={icon.icon} alt='icon' width={30} height={30} />
                        </p>
                      </div>
                      <div className='fs-7'>{icon.name}</div>
                    </div>
                  ))
              : icons?.map((icon: any, index: any) => (
                  <div className='col-3 rounded text-center my-1' key={index}>
                    <div
                      style={{width: '100px', height: '50px'}}
                      className='symbol symbol-35px text-center bg-secondary align-items-center pointer'
                      onClick={() => {
                        setSubmissionData({
                          ...submissionData,
                          icon: icon.icon,
                        })
                        handleClose()
                      }}
                      data-bs-toggle='tooltip'
                      title='Ana Stone'
                    >
                      <p className='fw-bold fs-4 text-primary mb-0 mt-4'>
                        <img src={icon.icon} alt='icon' width={30} height={30} />
                      </p>
                    </div>
                    <div className='fs-7'>{icon.name}</div>
                  </div>
                ))}
          </div>
        </Box>
      </Modal>
      <Modal
        open={open1}
        onClose={() => {
          handleClose1()
          setSubmissionData({
            ...submissionData,
            name: '',
            icon: '',
          })
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className='overflow-scroll' sx={style}>
          <div className='modal-header'>
            {/* begin::Modal title */}
            {data?.level == 1 ? (
              <h2 className='fw-bolder'>Update Channel</h2>
            ) : (
              <h2 className='fw-bolder'>Update Sub Channel</h2>
            )}
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={() => {
                handleClose1()
                setSubmissionData({
                  ...submissionData,
                  name: '',
                  icon: '',
                })
              }}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <form className='text-center m-5'>
            <InfoWrapper bar={'down'}>
              <div className='row mb-4 text-start'>
                <div className='col-lg-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Name</span>
                  </label>

                  <div className='fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Service Name'
                      value={submissionData?.name}
                      onChange={(e) =>
                        setSubmissionData({
                          ...submissionData,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                {data?.level == 1 && (
                  <div className='col-lg-6'>
                    <label className='col-form-label fw-bold fs-6'>
                      <span className='required'>Service type</span>
                    </label>

                    <div className='fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Service type'
                        readOnly
                        value={submissionData?.serviceType}
                        // onChange={(e) =>
                        //   setSubmissionData({
                        //     ...submissionData,
                        //     name: e.target.value,
                        //   })
                        // }
                      />
                    </div>
                  </div>
                )}
                <div className='col-lg-6'>
                  <label className='col-form-label fw-bold fs-6'>
                    <span className='required'>Icon</span>
                  </label>

                  <div className='d-flex'>
                    <input
                      type='text'
                      // onChange={(e) => {
                      //   setSubmissionData({
                      //     ...submissionData,
                      //     icon: e.target.value,
                      //   })
                      // }}
                      value={submissionData?.icon}
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Icon Link'
                    />
                    <MuiTooltip title='Add Icon from Library' onClick={handleOpen}>
                      <div className='col-lg-1 text-center m-auto'>
                        <CgPathDivide className='ms-3 pointer' size={'2rem'} />
                      </div>
                    </MuiTooltip>
                  </div>
                </div>
              </div>
            </InfoWrapper>
            {/* {data?.level == 1 ? (
            ): null} */}
            <div className='row'>
              <div className='mb-4 col-6'>
                <div className='d-flex justify-content-between'>
                  <label className='col-form-label fw-bold fs-6'>Member Default</label>

                  <div className='d-flex align-items-center'>
                    <div className='form-check form-check form-switch fv-row'>
                      <input
                        checked={submissionData?.isMemberDefault === 1}
                        onChange={(e) => {
                          if (data?.isAdmin === 2 || data?.isAdmin === 3) {
                            return
                          } else {
                            setSubmissionData({
                              ...submissionData,
                              isMemberDefault: e.target.checked ? 1 : 0,
                            })
                          }
                        }}
                        className={`form-check-input w-40px h-25px ${
                          data?.isMemberDefault ? '' : ''
                        }`}
                        disabled={
                          data?.isAdmin === 2 ||
                          data?.isAdmin === 3 ||
                          submissionData?.isActive !== 1
                        }
                        type='checkbox'
                        id='isMemberDefault'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-4 col-6'>
                <div className='d-flex justify-content-between'>
                  <label className='col-form-label fw-bold fs-6'>Active</label>

                  <div className='d-flex align-items-center'>
                    <div className='form-check form-check form-switch fv-row'>
                      <input
                        checked={submissionData?.isActive ? true : false}
                        onChange={(e) => {
                          setSubmissionData({
                            ...submissionData,
                            isActive: e.target.checked ? 1 : 0,
                            isMemberDefault: submissionData?.isActive === 0 && 0,
                          })
                        }}
                        className={`form-check-input w-40px h-25px ${
                          submissionData?.isActive ? '' : ''
                        }`}
                        type='checkbox'
                        id='isActive'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='mb-4 col-6'>
                <div className='d-flex justify-content-between'>
                  <label className='col-form-label fw-bold fs-6'>Admin</label>

                  <div className='d-flex align-items-center'>
                    <div className='form-check form-check form-switch fv-row'>
                      <input
                        defaultChecked={data?.isAdmin === 1}
                        onChange={(e) => {
                          if (data?.isAdmin !== 1) {
                            setSubmissionData({
                              ...submissionData,
                              isAdmin: e.target.checked ? 1 : 0,
                            })
                          }
                        }}
                        disabled={data?.isAdmin === 1}
                        className={`form-check-input w-40px h-25px ${
                          submissionData?.isAdmin ? '' : ''
                        }`}
                        type='checkbox'
                        id='isAdmin'
                      />
                      <label className='form-check-label'></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mt-20'>
              <button
                onClick={(e) => {
                  handleClose1()
                  handleSubmit(e)
                }}
                type='button'
                className='btn btn-primary mt-4'
                aria-label='submit data'
              >
                Submit
              </button>
              {data?.level == 2 && (
                <button
                  onClick={(e) => {
                    handleOpen2()
                    handleClose1()
                  }}
                  type='button'
                  className='btn btn-primary mt-4 ms-4'
                >
                  Transform
                </button>
              )}
              <button
                onClick={() => {
                  setIsCopy(!isCopy)
                }}
                type='button'
                className='btn btn-primary mt-4 ms-4'
              >
                {isCopy ? 'Cancel Copy' : 'Copy sub channel'}
              </button>
            </div>
            {isCopy && (
              <div className='mt-2'>
                <select
                  name=''
                  id=''
                  onChange={(e) => {
                    setCopyData({
                      parentId: e.target.value,
                      serviceIds: [submissionData?.id],
                    })
                  }}
                  className='form-select'
                >
                  <option value=''>Select channel</option>
                  {channels?.map((service: any) => (
                    <option value={service?.id}>{service?.name}</option>
                  ))}
                </select>
                <button
                  onClick={(e) => {
                    handleCopySubmit()
                  }}
                  type='button'
                  className='btn btn-primary mt-4 ms-4'
                >
                  Copy
                </button>
              </div>
            )}
          </form>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>Update Channel</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
              onClick={() => {
                handleClose2()
              }}
              className='btn btn-icon btn-sm btn-active-icon-primary'
              style={{cursor: 'pointer'}}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </div>
            {/* end::Close */}
          </div>
          <form className='text-center m-5'>
            <div className='row mb-4 text-start'>
              <label className='col-sm-3 col-form-label fw-bold fs-6'>
                <span>Sub Channel</span>
              </label>

              <div className='col-sm-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Service Name'
                  value={submissionData?.name}
                  disabled={true}
                />
              </div>
            </div>
            <div className='row mb-4 text-start'>
              <label className='col-sm-3 col-form-label fw-bold fs-6'>
                <span>Current Channel</span>
              </label>

              <div className='col-sm-9 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Service Name'
                  value={activeSecondLvl[0]?.name}
                  disabled={true}
                />
              </div>
            </div>
            <div className='row mb-4 text-start'>
              <label className='col-sm-3 col-form-label fw-bold fs-6'>
                <span className='required'>New Channel</span>
              </label>

              <div className='col-sm-9 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  onChange={(e) => {
                    setUpdateParentData({
                      serviceId: submissionData?.id,
                      newParentId: Number(e.target.value),
                    })
                  }}
                  // value={submissionData?.searchAbleId}
                >
                  <option value=''>Select a Parent...</option>
                  {channels.map((channel: any) => (
                    <option value={channel?.id}>{channel?.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {error && <p className='text-danger'>New Channel field is required for continue</p>}
            <button
              onClick={(e) => {
                handleParentUpdate(e)
              }}
              type='button'
              className='btn btn-primary mt-20'
              aria-label='submit data'
            >
              Transform
            </button>
          </form>
        </Box>
      </Modal>
    </div>
  )
}

export default ServiceCard
